import React from "react";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import CompanyMaintenance from "./CompanyMaintenance";
import CompanyDetailContMain from "presentation/view/container/Company/CompanyDetailContMain";

export const CompanyMDView: React.FC = () => {
     const [companyState] = useCompanyMaintenanceTracked();
     const isShowDetail = companyState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <CompanyMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><CompanyDetailContMain/> </div>
    } 

    </>
}
