import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CompanySearchForm from "./CompanySearchForm";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";

const CompanySearchPanel = () => {
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const [companyState] = useCompanyMaintenanceTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        companyMaintenanceVM.onRemoveAllSearchCriteria();
    }, [companyMaintenanceVM]);
    
    const searchButtonClicked = useCallback(() => {
        setIsLoading(true);
        companyMaintenanceVM.onSearch(companyState.searchCriteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [companyMaintenanceVM, companyState.searchCriteria])

    const isDisableSearchBtn = useCallback(() => {
        if(companyState.isShowRightPanel) return true;
         return false;
    }, [companyState.isShowRightPanel]);

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={companyMaintenanceVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <CompanySearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} disabled={isDisableSearchBtn()} onClick={() => searchButtonClicked()} />
            </SidebarActionBar>
        </div>
    );
}

export default CompanySearchPanel;
