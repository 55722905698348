import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { CompanyEnabledSearchCriteria } from "presentation/constant/Company/CompanyEnabledSearchCriteria";
import { CompanySearchCriteria, EMPTY_COMPANY_SEARCH_CRITERIA } from "presentation/constant/Company/CompanySearchCriteria";
import { commonCompanyValidationSchema } from "presentation/constant/Company/CompanyValidationSchema";
import { Validation } from "presentation/constant/Validation";

import { CompanyMaintenanceModel } from "presentation/model/Company/CompanyMaintenanceModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyMaintenanceModel>> | ((value: SetStateAction<CompanyMaintenanceModel>) => void),
    ]    
    companyRepo: CompanyRepository,
    searchPreferenceRepo: SearchPreferenceRepository<CompanySearchCriteria,CompanyEnabledSearchCriteria>,
    masterDataRepo: MasterDataRepository
}

export const CompanyMaintenanceVM = ({ dispatch,  companyRepo, masterDataRepo}: CompanyMaintenanceVMProps) => {
    const [companyDispatch] = dispatch;

    const onRemoveAllSearchCriteria = () => {
        companyDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            
            return {
                ...prevState, searchCounter: 0, searchCriteria: { ...EMPTY_COMPANY_SEARCH_CRITERIA }
            }
        })
    }

    const onSearch = async (searchCriteria: CompanySearchCriteria) => {
        return await companyRepo.searchCompany(
            searchCriteria            
        ).then((data) => {
            companyDispatch(prevState => {
                return {
                    ...prevState,
                    companys: data,
                    selectCompanyRows: [],
                    searchCriteria: {...searchCriteria},
                    isAllowAutoSearch: false
                }
            })
        }).catch((error) => {
            return [];
        })        
    }

    const loadDropdownOption = async() => {        
        await masterDataRepo.getMasterDataByKey(MasterDataType.COMPANY_TYPE).then(
            companyTypes => {
                const companyTypeDropdownOptions = companyTypes?.map((companyType)=>({
                    dropdownLabel: companyType.code,
                    tagLabel: companyType.code,
                    value: companyType.code,
                })) ?? []

                companyDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyTypeDropdownOptions: [
                            ...companyTypeDropdownOptions]
                    }
                }))
            }
        )  
        
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let masterCompanies = companies?.filter(company => company.companyType === 'MASTER');
                let allianceCompanies = companies?.filter(company => company.companyType === 'ALLIANCE');

                let masterCompanyDropdownOptions = masterCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                masterCompanyDropdownOptions = _.orderBy(masterCompanyDropdownOptions, "dropdownLabel");

                let allianceCompanyDropdownOptions = allianceCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                allianceCompanyDropdownOptions = _.orderBy(allianceCompanyDropdownOptions, "dropdownLabel");

                companyDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        masterCompanyDropdownOptions: masterCompanyDropdownOptions,
                        allianceCompanyDropdownOptions: allianceCompanyDropdownOptions
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.COUNTRY).then(
            countryCodes => {
                const countryCodeDropdownOptions = countryCodes?.map((countryCode)=>({
                    dropdownLabel: countryCode.code,
                    tagLabel: countryCode.id.toString(),
                    value: countryCode.code,
                })) ?? []

                companyDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        countryCodeDropdownOptions: [
                            ...countryCodeDropdownOptions]
                    }
                }))
            }
        )        
    }

    const onInitDefaultValue = () => { 
        companyDispatch(prevState => {            
            return {
                ...prevState,
                currentCompany: {
                    ...prevState.currentCompany,
                    companyType: prevState.currentCompany.id !== 0? prevState.currentCompany.companyType
                        : (prevState.dynamicOptions.companyTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.companyTypeDropdownOptions[0].value : ''),
                    countryCodeId: prevState.currentCompany.id !== 0 && prevState.currentCompany.countryCodeId? prevState.currentCompany.countryCodeId
                        : (prevState.dynamicOptions.countryCodeDropdownOptions.length > 0 ? Number(prevState.dynamicOptions.countryCodeDropdownOptions[0].tagLabel) : null)
                }
            }
        }); 
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        companyDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "subChgTypeList" ? [] : '') : e),
            }
        }))
    }

    const onInputTextChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {    
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if(fieldKey === 'companyCode'){
            val = val.toUpperCase();
        }
        return companyDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldKey]: val,
            }
        }))
    };

    const onDropdownKeyChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        companyDispatch(prevState => {            
            const masterCompanies = prevState.dynamicOptions.masterCompanyDropdownOptions;
            const allianceCompanies = prevState.dynamicOptions.allianceCompanyDropdownOptions;
            const countryCodes = prevState.dynamicOptions.countryCodeDropdownOptions;

            if(fieldKey === 'masterCompanyId' && masterCompanies && masterCompanies.length > 0){
                const masterCompany = masterCompanies.find(masterComp => val === masterComp.value);
                val = Number(masterCompany?.tagLabel)??null;                
            } else if(fieldKey === 'allianceCompanyId' && allianceCompanies && allianceCompanies.length > 0){
                const allianceCompany = allianceCompanies.find(allianceComp => val === allianceComp.value);
                val = Number(allianceCompany?.tagLabel)??null;
            } else if(fieldKey === 'countryCodeId' && countryCodes && countryCodes.length > 0){
                const countryCode = countryCodes.find(countryCode => val === countryCode.value);
                val = Number(countryCode?.tagLabel)??null;
            }

            return {
                ...prevState,
                currentCompany: {
                    ...prevState.currentCompany,
                    [fieldKey]: val
                }
            }
        });
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        
        if(fieldKey === 'companyCode'){
            val = val.toUpperCase();
        }

        companyDispatch(prevState => ({
            ...prevState,
            currentCompany: {
                ...prevState.currentCompany,
                [fieldKey]: val
            }
        }))
    }

    const onSearchClick = async() => {
        companyDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }

    const onAdd = () => {
        return companyDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,
                currentCompany: EMPTY_COMPANY_ENTITY,
                viewState: {
                    ...prevState.viewState,
                    isAdd: true,
                    isEditable: false
                }
            }
        });       
    }

    const onDetail = (currCompany: CompanyEntity) => {
        companyDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: true,
                currentCompany: currCompany,
            }
        });   
    }

    const onSelectedCompanys = (selectedRows: CompanyEntity[]) => {
        companyDispatch(prevState => {
            return {
                ...prevState,
                selectCompanyRows: selectedRows,
                currentCompany:(selectedRows.length===1?selectedRows[0]:EMPTY_COMPANY_ENTITY),
                isShowRightPanel: (selectedRows.length===1?true:false),
                viewState:{
                    ...prevState.viewState,
                    isEditable: true,
                    isAdd:false  
                }               
            }
        })
    }

    const onCancel = () => {
        companyDispatch(prevState => {
            return {
                ...prevState,
                currentCompany: EMPTY_COMPANY_ENTITY,
                isShowRightPanel:false,
                viewState: {
                    ...prevState.viewState,
                    isAdd:false,
                    isEditable: false,
                    isSliderOpen: false,
                    allowAutoRefresh: false,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentCompany: CompanyEntity, isAdd: boolean ) => {
        const valResult = await Validation(commonCompanyValidationSchema).ValidateFormOnly(currentCompany);

        if (valResult) {
            let allValResult: { [x: string]: string } = {};

            if (valResult) {
                allValResult = {
                    ...allValResult, ...valResult,
                    mandatoryCheckFail: 'Please input the missing value.'
                };
            }


            companyDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }
                }
            });
            return { "saveCompanyFailed": 'Please input the missing value.' };
        } else {
            return await (isAdd ? companyRepo.createNewCompany(currentCompany) : companyRepo.updateCompany(currentCompany)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveCompanyFailed": data.toString() }
                        };
                    });
                    return { "saveCompanyFailed": data.toString() };
                } else {
                    if (data) {                        
                        return companyDispatch(prevState => {
                            return {
                                ...prevState,
                                companys: [],
                                currentCompany: EMPTY_COMPANY_ENTITY,
                                selectCompanyRows: [],
                                isShowRightPanel: false,
                                viewState: {
                                    ...prevState.viewState,
                                    isAdd: false,
                                    isRead: true,
                                    isEditable: false,
                                    isSaveClicked: false,
                                    lastEditRowId: '',
                                    isSliderOpen: false,
                                    allFormState: {}
                                }
                            }
                        })                        
                    }
                }
            })
        }
    }

    const onConfirm = async (selectedRows: CompanyEntity[]) => {
        return await companyRepo.confirmCompanies(selectedRows).then((data) => {
            if (data) {

            } else {
                throw new Error(data as string)
            }
            return data;
        }).catch((error) => {
            return [];
        })       
    }


    return {
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,       
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onDropdownChange: onDropdownChange,
        onInputTextChange: onInputTextChange,        
        onSearchClick: onSearchClick,
        onAdd: onAdd,
        onDetail: onDetail,
        onSelectedCompanys: onSelectedCompanys, 
        onFieldChange: onFieldChange,
        onCancel: onCancel,
        onSave: onSave,
        onInitDefaultValue: onInitDefaultValue,
        onConfirm: onConfirm,
        onDropdownKeyChange: onDropdownKeyChange
    }
}