import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyTitleBar:React.FC = () => {
    const [ companyState ] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const messageBarVM = useMessageBarVM();    
    
    const [isLoading, setIsLoading] = useState(false);    
    const selectRows = companyState.selectCompanyRows;
    const {isAdd, isEditable} = companyState.viewState

    const [anainfoState] = useANAInfoTracked();
    const {allowCreate,allowUpdate,allowDelete} = anainfoState;

    const handleSearchClick = useCallback(() => {
        companyMaintenanceVM.onSearchClick();
    }, [companyMaintenanceVM]);


    const handleAdd = useCallback(() => {
        companyMaintenanceVM.onAdd();
    }, [companyMaintenanceVM]);    
    
    const handleConfirm = useCallback(() => {
        companyMaintenanceVM.onConfirm(selectRows).then((data) => {
            if(data){
                setIsLoading(false);
                companyMaintenanceVM.onSearch(companyState.searchCriteria).then(()=>{
                        setIsLoading(false);
                    }).catch((error) => {                    
                        setIsLoading(false);
                    });
            }            
        }).catch(()=>{
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });
           
    }, [companyMaintenanceVM, companyState.searchCriteria, messageBarVM, selectRows]);

    const isDisable = () => {
        const confirmedRows = selectRows?.filter(row => row.status === 'CONFIRMED');
        if (selectRows && selectRows.length > 0 && !(confirmedRows.length > 0)) {
            return false;
        }
        return true;
    }
    

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{CompanyConstant.Header.COMPANY_MAIN}</HeaderTitle>       
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={isAdd || isEditable} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={isAdd} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowDelete || allowUpdate) && <div className="add-seperator"/>}
            
            {(allowUpdate) && <>            
            <HPHButton label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} disabled={isDisable()} theme={'Primary'} onClick={handleConfirm} /></>}       
             
        </StyledAction>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(CompanyTitleBar);