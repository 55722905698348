import * as yup from 'yup';

export const commonCompanyValidation: { [x: string]: any; } = {
    companyCode: yup.string().required("Missing"),
    companyType: yup.string().required("Missing"),
    countryCodeId: yup.number().required("Missing"),
    signOnDate: yup.date().required("Missing"),
    activeInd: yup.string().required("Missing"),    
};

export const commonCompanyValidationSchema = yup.object().shape(commonCompanyValidation);
