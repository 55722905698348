import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import CompanyFormPanel from "./RightPanel/CompanyFormPanel";
import CompanyTablePanel from "./Table/CompanyTablePanel";

const CompanyContent:React.FC = () => {
        const [ companyState ] = useCompanyMaintenanceTracked();
        const { isShowRightPanel, isShowCriteriaPanel } = companyState;

    return <div className={`main-comp-wrapper${isShowRightPanel? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={isShowRightPanel}
                leftSectionWidth={isShowRightPanel? (isShowCriteriaPanel?'65%':'74%'):'35%'}
                rightSectionWidth={isShowRightPanel ? (isShowCriteriaPanel?'35%':'26%' ): '65%'}
                draggable={true}
                leftChildren={<CompanyTablePanel/>}
                rightChildren={<CompanyFormPanel />} />
        </div>
}

export default memo(CompanyContent);