import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CompanyMaintenanceProvider } from "presentation/store/Company/CompanyMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { CompanyMDView } from "presentation/view/section/Company/CompanyMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CompanyContMain = () => {
    return <ANAInfoWrapper permission={Permission.COMPANY_MAINTENANCE}>
        <MessageBarWrapper>
            <CompanyMaintenanceProvider>
                <GridStyles/>
                <CompanyMDView/>
            </CompanyMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CompanyContMain;