import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { CompanyMaintenanceVM } from "presentation/viewModel/Company/CompanyMaintenanceVM";
import { useMemo } from "react";

export const useCompanyMaintenanceVM = () => {
    const [, setCompanyMaintenanceState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useMemo(() =>
        CompanyMaintenanceVM({
            dispatch: [setCompanyMaintenanceState],            
            companyRepo: CompanyRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),            
            masterDataRepo: MasterDataRepoImpl(),
        }), [setCompanyMaintenanceState])

    return companyMaintenanceVM
}