import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_COMPANY_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyColumnDefinition";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const CompanyTablePanel: React.FC = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_COMPANY_COL_DEF.slice());

        if (!companyState.selectCompanyRows ||
            companyState.selectCompanyRows.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        if (selectedRows && selectedRows.length > 0) {
            companyMaintenanceVM.onSelectedCompanys(selectedRows);
        }
    }, [companyMaintenanceVM])

    const memoCompanyTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='company-maintenance-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_COMPANY_COL_DEF.slice()}
                    data={transferRowData(companyState.companys) ?? []}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any) => { companyMaintenanceVM.onDetail(e.data) }}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                />
            </>
        );
    }, [companyMaintenanceVM, companyState.companys, handleSelectionChange])

    return <><TableWrapper>{memoCompanyTable}</TableWrapper></>;
}

export default memo(CompanyTablePanel);
