
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CompanyContent from "./CompanyContent";
import CompanyTitleBar from "./CompanyTitleBar";
import CompanySearchPanel from "./LeftPanel/CompanySearchPanel";


const CompanyMaintenance:React.FC = () => {
    const [ companyState ] = useCompanyMaintenanceTracked();
    const { isShowCriteriaPanel, isBackFromDetail, searchCriteria ,isAllowAutoSearch} = companyState;
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const initialScreen = async() => {  
            setIsLoading(true); 
            try {         
                const results = await Promise.allSettled([
                    companyMaintenanceVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
            }
        }

        isAllowAutoSearch && initialScreen().then((data)=>{            
            companyMaintenanceVM.onSearch(searchCriteria).then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });        
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [isAllowAutoSearch, isBackFromDetail, companyMaintenanceVM, searchCriteria])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
          { <CompanyTitleBar/> }

          {  <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<CompanySearchPanel/>}
                rightChildren={<CompanyContent/>} 
                /> }
        </div>
    </>
}

export default memo(CompanyMaintenance);